/* Media queries breakpoints */

// MIN widths
// Tablet
$screen-tablet-min: 768px;
// Desktop
$screen-desktop-min: 992px;
// Wide desktop
$screen-desktop-wide-min: 1200px;

// MAX widths
// Phone
$screen-phone-max: ($screen-tablet-min - 1);
// Tablet
$screen-tablet-max: ($screen-desktop-min - 1);
// Desktop
$screen-desktop-max: ($screen-desktop-wide-min - 1);



/* Colors */
$dmg-primary: #fa8232;
$dmg-secondary: #2b3472;

$tys-primary: #fa8232;
$tys-secondary: #345e78;

$ggs-primary: #fa8232;
$ggs-secondary: #00457c;