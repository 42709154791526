.testimonial {
    margin-top: -15px;
    padding: 15px 0 15px 60px;
    background: url(../img/quote.png) no-repeat top left;
    p {
        color: #797d80;
        font-size: 16px;
        line-height: 1.6;
    }
    .name {
        color: $dmg-secondary;
        font-size: 16px;
        font-weight: bold;
    }
    .position {
        color: $dmg-primary;
    }
}
