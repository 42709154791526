.home-service {
    padding-bottom: 12rem;
    color: #121c2b;
    font-size: 18px;
    line-height: 1.6;
    .logo-holder {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100px;
        margin-bottom: 3rem;
    }
    .description-holder {
        margin-bottom: 5rem;
    }
    p {
        margin-bottom: 3rem;
    }
}
.gigasoft-service, .tellyourstory-service {
    padding-bottom: 12rem;
    color: #121c2b;
    font-size: 18px;
    line-height: 1.6;
    h2 {
        margin-bottom: 3rem;
        font-size: 28px;
    }
    .description-holder {
        margin-bottom: 5rem;
    }
    p {
        margin-bottom: 3rem;
    }
}

.service-logo {
    margin-bottom: 3rem;    
} 

.section-pt-2 {
    padding-top: 7rem;
}

.section-pt-3 {
    padding-top: 12rem;
}

.company-brief {
    margin-bottom: 7rem;
    color: #121c2b;
    font-size: 18px;
    p {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}