.btn {
    &-cta {
        min-width: 225px;
        padding: 1.5rem;
        font-size: 16px;

        .home-service & {
            margin-bottom: 0;
            border-color: $dmg-primary;
            background-color: $dmg-primary;
            color: #fff;

            &:hover {
                border-color: darken($dmg-primary, 10%);
                background-color: darken($dmg-primary, 10%);
            }
        }

        .gigasoft-service & {
            margin-bottom: 0;
            border-color: $ggs-secondary;
            background-color: $ggs-secondary;
            color: #fff;

            &:hover {
                border-color: darken($ggs-secondary, 10%);
                background-color: darken($ggs-secondary, 10%);
            }
        }
        .tellyourstory-service & {
            margin-bottom: 0;
            border-color: $tys-secondary;
            background-color: $tys-secondary;
            color: #fff;

            &:hover {
                border-color: darken($tys-secondary, 10%);
                background-color: darken($tys-secondary, 10%);
            }
        }
    }
}
