.dmg-navbar {
    margin-bottom: 0;

    .navbar-toggle {
        .icon-bar {
            background-color: $dmg-secondary;
        }
    }

    .dmg-utilities {
        height: 55px;
        background-color: #d0dce5;
        background-image: url(../img/utlt-backgr.jpg);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: top;
        box-shadow: inset 0 -4px 10px #c1cdd6;
    }

    .dmg-menu {
        @media screen and (min-width: $screen-tablet-min) {
            height: 66px;
        }

        background-color: #fff;
    }

    .utlt-flex {
        position: relative;
        display: flex;
        align-items: center;
        height: 55px;
        padding-left: 220px;

        @media screen and (max-width: $screen-phone-max) {
            padding-left: 110px;
        }

        .navbar-brand {
            position: absolute;
            top: 0;
            left: 0;
            float: none;
            background-color: #fff;
            text-align: center;

            @media screen and (min-width: $screen-tablet-min) {
                width: 210px;
                height: 121px;
            }

            @media screen and (max-width: $screen-phone-max) {
                width: 100px;
                height: 100px;
                padding: 5px;
            }

            > img {
                display: inline-block;

                @media screen and (max-width: $screen-phone-max) {
                    width: 50px;
                }
            }
        }

        .top-searchbar {
            @media screen and (max-width: $screen-phone-max) {
                display: none;
            }

            @media screen and (min-width: $screen-desktop-min) {
                min-width: 375px;
            }

            .form-group {
                position: relative;
                margin: 0;
            }

            .form-control {
                height: 36px;
                padding-right: 35px;
                border-radius: 5px;
                border-color: #c0cdd7;
            }

            .btn {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 50;
                margin: 0;
                background-color: transparent;
                border-color: transparent;
                color: #92a0aa;
                font-size: 15px;
            }
        }

        .user-menu {
            margin-left: auto;

            .quick-link {
                margin-right: 2rem;
                color: $dmg-secondary;
                font-size: 15px;
                font-weight: bold;

                i {
                    font-size: 12px;
                }
            }

            .btn {
                margin-bottom: 0;
                padding-left: 30px;
                padding-right: 30px;
            }

            .user-dd {
                display: inline-block;
                margin-left: 15px;

                .btn {
                    background-color: #1b2c3d;
                    border-color: #1b2c3d;
                    color: #fff;
                }

                .profile-pic {
                    margin-right: 10px;
                }

                .dropdown-menu {
                    right: 0;
                    left: auto;
                    margin-top: 0;
                    font-size: 12px;
                    border-radius: 1px 0 1px 1px;
                    min-width: 196px;
                    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.165);

                    > li > a {
                        padding: 7px 10px;
                    }

                    .divider {
                        margin: 9px 5px;
                    }
                }

                .dd-label {
                    padding: 3px 10px;

                    .label-divider {
                        width: 60px;
                        display: inline-block;
                    }
                }

                .dropdown-toggle {
                    padding: 6px 15px;
                    font-size: 13px;
                    margin-bottom: 0;
                }

                &:hover {
                    .dropdown-menu {
                        display: block;
                    }

                    .btn {
                        background-color: #fff;
                        border-color: #ccc;
                        color: #1b2c3d;
                        border-radius: 3px 3px 0 0;
                        border-bottom: none;
                    }
                }
            }
        }
    }

    .menu-flex {
        @media screen and (min-width: $screen-tablet-min) {
            display: flex;
            align-items: center;
            height: 66px;
            padding-left: 220px;
        }

        .navbar-toggle {
            margin-left: auto;
        }

        .navbar-nav {
            margin: 0;

            > li {
                position: inherit;

                > a, .dropdown > a {
                    display: inline-block;
                    padding: 15px 60px 15px 10px;
                    color: $dmg-secondary;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 1;

                    @media screen and (max-width: $screen-tablet-max) {
                        padding: 15px 30px 15px 10px;
                    }

                    &:hover, &:focus, &:active {
                        background-color: transparent;
                        color: #29bcf2;
                    }
                }

                &.active {
                    > a {
                        color: #29bcf2;
                    }
                }
            }

            .dropdown-full {
                position: inherit;
            }

            .sub-menu {
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }

                .service-menu {
                    display: block;
                    padding: 30px 0;

                    &:hover {
                        color: inherit;
                    }

                    img {
                        max-width: 150px;
                        max-height: 50px;
                        margin-bottom: 15px;
                    }
                }

                @media screen and (min-width: $screen-tablet-min) {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                    z-index: 500;
                    margin: 0 -1px;
                    border-top: 1px solid #ccc;
                    border-radius: 0;
                    background-color: #fff;

                    .service-menu {
                        padding: 30px;
                    }
                }
            }
        }

        @media screen and (min-width: $screen-tablet-min) {
            .navbar-collapse {
                padding: 0;
            }
        }
    }

    &.child-brand-navbar {
        .utlt-flex {
            @media screen and (max-width: $screen-phone-max) {
                padding-left: 80px;
            }

            .navbar-brand {
                padding: 16px 0;
                background-color: transparent;
                height: 55px;

                > img {
                    @media screen and (max-width: $screen-phone-max) {
                        width: 90px;
                    }
                }
            }
        }

        .menu-flex {
            position: relative;

            @media screen and (min-width: $screen-tablet-min) {
                padding-left: 260px;
            }

            .navbar-nav {
                @media screen and (max-width: $screen-phone-max) {
                    padding-top: 75px;
                }
            }
        }

        .dropdown-brand {
            position: absolute;
            top: 0;
            left: 0;
            width: 210px;

            .caret {
                float: right;
                margin-left: 2px;
                margin-top: 20px;
                margin-right: 5px;
            }

            > a {
                display: block;
                padding: 5px 0;

                @media screen and (min-width: $screen-tablet-min) {
                    padding: 9px 0;
                }
            }

            img {
                max-width: 120px;
                max-height: 41px;

                @media screen and (min-width: $screen-tablet-min) {
                    max-width: 147px;
                    max-height: 50px;
                }
            }

            .tys-logo {
                margin: 9px 0;
            }

            .dropdown-menu {
                width: 210px;
                margin: 0;
                border-radius: 0;

                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                }
            }
        }
    }
}
