.contacts-card {
    margin-bottom: 6.5rem;
    color: $dmg-primary;
    font-size: 24px;
    .title {
        margin-bottom: 2rem;
        color: $dmg-secondary;
        font-size: 31px;
    }
    .contact-icon {
        margin-right: 1rem;
    }
}