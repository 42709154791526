.tellyourstory-home {
    padding: 8rem 0;
    background: url(../img/tellyourstory-hero.jpg) no-repeat center center;
    background-size: cover;
    h1 {
        padding-top: 0;
        color: #fff;
        font-size: 38px;
        font-weight: bold;
    }
    p {
        color: #fff;
        font-size: 21px;
    }
}
