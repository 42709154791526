footer {
    font-size: 14px;
    h4 {
        font-size: 16px;
    }
    .copyright {
        padding-top: 15px;
        border-top: 1px solid #404b5a;
        color: #556270;
    }
}