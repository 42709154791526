.section-shade {
    background: url(../img/section-bg-pattern.jpg) repeat-x top left;
}
.section-shade-bottom {
    background: url(../img/section-bottom-bg-pattern.jpg) repeat-x bottom left;
}

.section-gradient {
    background: linear-gradient(#f4f4f4, #e1e1e1);
}

.section-border-bottom {
    border-bottom: 5px solid #f4f4f4;
}